import React, { useContext } from "react";
import SectionHeader from "../../components/Headers/SectionHeader";
import TextRowWithAsset from "../../components/TextRowWithAsset";
import { ProductsDataContext } from "../../context";

const Urunler = () => {
  const data = useContext(ProductsDataContext);

  return (
    // exact same layout with the "ekipmanlar" section
    <section id="ekipmanlar"> 
      <SectionHeader text="Ürünler" />
      {data?.edges.map(({ node }, index) => (
        <TextRowWithAsset
          key={`${node.id}`}
          asset={node?.urun_resmi?.childImageSharp.gatsbyImageData}
          heading={node.urun_ismi}
          text={node.urun_aciklamasi}
          reverse={index % 2 === 0}
          secondParagraph={node?.urun_aciklamasi_2}
          relatedNewsSlug={node?.iliskili_haber?.slug}
        />
      ))}
    </section>
  );
};
export default Urunler;
